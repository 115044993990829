export default class Misc {
	
	constructor() {
		this.init()
	}

	init() {
		this.ImageBannerFollowlink();
	}

	ImageBannerFollowlink() {
		[...document.querySelectorAll('.image-banner-block--has-link')].forEach(el => {
            new ImageBanner(el);
        })
	}
}

class ImageBanner {
	width;
	height;
	tooltip;
	tooltipTop;
	isActive = false;
	moveEvent;

	constructor(el) {
		this.init(el)
	}
	
	init(el) {
		this.tooltip = el.querySelector('.btn-tooltip');
		if(!this.tooltip) return;

		this.width = el.getBoundingClientRect().width;
		this.height = el.getBoundingClientRect().height;

		this.tooltipTop = parseInt(window.getComputedStyle(this.tooltip).top);

		window.addEventListener('resize', () => {
			this.tooltipTop = parseInt(window.getComputedStyle(this.tooltip).top);
			this.width = el.getBoundingClientRect().width;
			this.height = el.getBoundingClientRect().height;
		});

		el.addEventListener('mousemove', (e) => {
			this.moveEvent = e;
		});

		el.addEventListener('mouseenter', () => {
			this.tooltip.classList.add('follow');
			this.isActive = true;

			requestAnimationFrame(this.animate);
		});

		el.addEventListener('mouseleave', () => {
			this.isActive = false;
		});
		
		el.addEventListener('click', () => {
			this.tooltip.click();
		});
	}
	
	animate = () => {
		// this.tooltip.style.top = this.clamp(this.moveEvent.layerY - 10, 30, this.height - this.tooltip.scrollHeight - 30) + 'px';
		// this.tooltip.style.right = this.clamp(this.width - this.moveEvent.layerX - 10, 30, this.width - this.tooltip.scrollWidth - 30)  + 'px';

		let offsetX = 25 + 30 - this.width + this.moveEvent.layerX;
		let offsetY = -45 - this.tooltipTop + this.moveEvent.layerY;

		this.tooltip.style.translate = 
										this.clamp(offsetX, - this.width + this.tooltip.scrollWidth + 60, 0) 
										+ 'px ' 
										+ this.clamp(offsetY, 30 - this.tooltipTop, this.height - this.tooltip.scrollHeight - this.tooltipTop - 30) 
										+ 'px';

		if (this.isActive) {
			requestAnimationFrame(this.animate); // Call self again, if still active
		}
		else {
			this.tooltip.classList.remove('follow');
			this.tooltip.removeAttribute('style');
		}
	}

	clamp(num, min, max) {
		return Math.min(Math.max(num, min), max);
	}

}