import './main.scss';
import './components/competence';
import './components/curiosity';
import Hero from './modules/hero';
import DarkMode from './modules/darkmode';
import MegaMenu from './modules/mega-menu';
import Misc from './modules/misc';


class Main {
    constructor() {
        this.init();
    }

    init() {
        new DarkMode();
        new MegaMenu();
        new Hero();
        new Misc();

        window.addEventListener("load", function(){
            document.body.classList.add('ready');
        });
    }
}

new Main();