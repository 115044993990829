export default class MegaMenu {

    constructor() {
        this.triggerEls = document.querySelectorAll('.mega-menu__trigger');
        this.megaMenuEl = document.querySelector('.mega-menu__wrapper');
        this.navEl = this.megaMenuEl.querySelector('nav');

        this.open = false;

        this.init();
    }

    init() {
        this.addEvents();
    }

    addEvents() {
        [...this.triggerEls].forEach((el) => {
            el.addEventListener('click', (event) => {
                this.setState(!this.open);
            })
        });

        this.navEl.addEventListener('focusout', (event) => {
            this.setState(false);
        });

        this.navEl.addEventListener('focusin', (event) => {
            this.setState(true);
        });

        document.addEventListener('keydown', (event) => {
            if(event.key === 'Escape') {
                this.setState(false);
            }
        });
    }

    setState(state) {
        this.open = state;
        if(this.open) {
            this.megaMenuEl.classList.add('menu-open');
            this.triggerEls[0].classList.add('menu-open');
        }
        else {
            this.megaMenuEl.classList.remove('menu-open');
            this.triggerEls[0].classList.remove('menu-open');
        }
    }
}