<script setup lang="ts">
	import { onMounted, ref, Ref } from 'vue';

	const reduceMotion = window.matchMedia(`(prefers-reduced-motion: reduce)`).matches;

	const competencewrapper: Ref<HTMLElement | null> = ref(null);

	interface Area {
		id: number,
		title: string,
		description: string
	}
	const areas: Area[] = [
		{
			id: 1,
			title: 'Elektronikk',
			description: 'Kureo kan være din partner gjennom hele utviklingsløpet til et produkt. Vi gjør alt fra spesifikasjon, valg av komponenter, design, skjemategning, utlegg av kretskort, generering av produksjonsunderlag og utvikling av testutstyr for bruk under elektronikkutvikling.'
		},
		{
			id: 2,
			title: 'Programvareutvikling',
			description: 'Verktøyskassen vår tar utgangspunkt i Microsoft\'s .NET utviklingsplatform samt C, C++, javascript, python og andre språk ved behov. Valget av teknologi er prosjektavhengig og vi bruker det vi etter vår erfaring mener er best tilpasset de aktuelle behov.'
		},
		{
			id: 3,
			title: 'Prosjektgjennomføring',
			description: 'Ingen prosjekter er like. Selv om det finnes overordnede likhetstrekk er omstendighetene, hensikten og motivasjonen forskjellig. Dette gjør prosjektledelse til en krevende oppgave. Vi tilpasser oss våre oppdragsgivere og hvert enkelt prosjekts natur og benytter all vår erfaring fra tidligere for å få en så effektiv prosjektgjennomføring som mulig.'
		}
	]

	const selectedArea: Ref<number> = ref(1);
	
	
	onMounted(() => {
		const vpHeight = document.documentElement.clientHeight; //window.innerHeight;
		const headerHeight = document.querySelector('.header').getBoundingClientRect().height;	
		const vpScrollHeight = Math.round( 100 - (headerHeight / vpHeight * 100));

		const animateWrapEl = competencewrapper.value?.closest('.wp-block-group');

		const headerEl = document.querySelector('.header');
			if(headerEl) {
				headerEl.animate(
					{
						backgroundColor: ['var(--orange)', 'var(--header-bg-color)'],
						color: ['var(--white)',  'var(--header-text-color)'],
					},
					{
						timeline: new ViewTimeline({
							subject: animateWrapEl,
						}),
						rangeStart: 'exit '+vpScrollHeight+'%',
						rangeEnd: 'exit calc(100% + 100px)',
						fill: 'both',
					}
				);
			}
			if(!reduceMotion) {

				competencewrapper.value?.animate(
					{
						translate: ['0 0', '0 12rem']
					},
					{
						timeline: new ViewTimeline({
							subject: animateWrapEl,
						}),
						rangeStart: 'exit 0%',
						rangeEnd: 'exit calc(100% + 100px)',
						fill: 'both',
						
					}
				)
				
				document.body.animate(
					{
						backgroundColor: ['var(--orange)', 'var(--bg-color)'],
						color: ['var(--white)',  'var(--text-color)'],
					},
					{
						timeline: new ViewTimeline({
							subject: animateWrapEl,
						}),
						rangeStart: 'exit '+vpScrollHeight+'%',
						rangeEnd: 'exit calc(100% + 100px)',
						fill: 'both',
						
					}
				);
			}
	})

	function selectArea(e: Event, a:Area) {
		e.preventDefault();
		selectedArea.value = a.id;
		console.log(selectedArea);
	}

	function reset() {
		// setTimeout(()=> {
		// 	if(competencewrapper.value && !competencewrapper.value.contains(document.activeElement)) {
		// 		selectedArea.value = 0;
		// 	}
		// }, 500);
	}
</script>

<template>
	<div class="kureo-competence-wrapper" ref="competencewrapper">
		<div class="kureo-competence">
			<div class="kureo-competence__cards">
				<template v-for="a in areas">
					<a href="#" @click="(e) => selectArea(e, a)" @blur="reset" class="kureo-competence__card" :class="{'active': selectedArea === a.id}">
						<span>{{a.title}}</span>
					</a>
					<div class="btn-label"><span>{{a.title}}</span></div>
				</template>
			</div>
			<div class="kureo-competence__descriptions">
				<div class="kureo-competence__description" :class="{'active': selectedArea === 0}" ref="baseDesc">
					<h3>Fra idé til produkt</h3>
					<p class="has-black-color">Kureo AS har over 50 års tilstedeværelse i norsk teknologi og har i løpet av denne tiden gått gjennom flere hamskifter. Felles for dem alle er lidenskapen for innovasjon og gode løsninger.</p>
				</div>
				<template v-for="a in areas">
					<div class="kureo-competence__description" :class="{'active': selectedArea === a.id}">
						<h3>0{{ a.id }} {{ a.title }}</h3>
						<p class="has-black-color">{{ a.description }}</p>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
	@use '../../scss/variables' as *;
	// @use "sass:map";
	.kureo-competence-wrapper {
		//margin-bottom: var(--wp--preset--spacing--80);
		height: 50vh;

		@media(max-width: 720px) {
			height: auto;
			margin-top: 50px;
			margin-bottom: 150px;
		}
	}
	.kureo-competence {
		display: flex;
		justify-content: space-between;
		height: 100%;
		@media(min-width: $media-m) {
			margin-left: -150px;
		}

		@media(min-width: 721px) {
			margin-left: 20px;
		}

		@media(max-width: 720px) {
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	.kureo-competence__cards {
		width: 350px;
		flex-shrink: 0;
		color: var(--white);
		text-decoration: none;
		position: relative;

		@media(max-width: 720px) {
			width: 100%;
			height: 350px;
		}

		@media(max-width: 400px) {
			width: 280px;
		}
	}

	.kureo-competence__card {
		position: absolute;
		top: 50%;
		left: 70px;
		translate: -50%, 50%;
		width: 150px;
		height: 150px;
		background-color: var(--black);
		transform: rotateZ(135deg) skew(20deg, 20deg);
		transition: scale .25s, opacity .25s, translate .25s;
		z-index: 1;

		@media(max-width: 720px) {
			left: calc(50% - 75px);
		}

		@media(max-width: 400px) {
			width: 120px;
			height: 120px;
			left: calc(50% - 60px);
		}

		translate: 0 -50%;

		&:hover,
		&:focus,
		&.active {
			outline: none;
			scale: 1.2;
			opacity: 0.75 !important;
			z-index: 2;

			& ~ .kureo-competence__card {
				opacity: 0.25;
				translate: 0 calc(-50% + 30px);

				&+.btn-label {
					translate: -6px calc(-50% + 30px);
				}
			}

			& + .btn-label + .kureo-competence__card {
				opacity: 0.5 !important;
			}

			& + .btn-label {
				opacity: 1;
			}
		}

		&:has(~ .kureo-competence__card:hover),
		&:has(~ .kureo-competence__card:focus),
		&:has(~ .kureo-competence__card.active) {
			opacity: 0.25;
			translate: 0 calc(-50% - 30px);
			&+.btn-label {
				translate: -6px calc(-50% - 30px);
			}
		}

		&:has(+ .btn-label + .kureo-competence__card:hover),
		&:has(+ .btn-label + .kureo-competence__card:focus),
		&:has(+ .btn-label + .kureo-competence__card.active) {
			opacity: 0.5 !important;
		}

		&.active {
			opacity: 1 !important;
			outline: none;
			&+.btn-label {
				background-color: transparent;
				backdrop-filter: unset;

				span {
					opacity: 0;
				}
			}
		}

		span {
			opacity: 0;
		}

		&+.btn-label {
			position: absolute;
			top: 50%;
			left: 50%;
			translate: -6px -50%;
			z-index: 3;
			white-space: nowrap;
			opacity: 0;
			transition: all 0.25s;
			pointer-events: none;

			@media(max-width: 720px) {
				left: calc(50% - 75px);
			}

			&:before,
			&:after {
				content: "";
				display: block;
				width: 8px;
				height: 8px;
				background-color: var(--orange);
				position: absolute;
				left: -30px;
				top: 50%;
				translate: 0 -50%;
			}

			&:after {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				opacity: 0.3;
				translate: -6px -50%;
			}
		}

		&:nth-of-type(1) {
			top: calc(50% - 50px);
			&+.btn-label {
				top: calc(50% - 50px);
			}
		}

		&:nth-of-type(2) {
			opacity: 0.5;
		}

		&:nth-of-type(3) {
			top: calc(50% + 50px);
			opacity: 0.25;

			&+.btn-label {
				top: calc(50% + 50px);
			}
		}
	}

	.kureo-competence__descriptions {
		display: flex;
		width: 100%;
		height: 100%;
		position: relative;
		margin-left: 20px;

		@media(max-width: 720px) {
			height: auto;
			margin-left: 0;
		}
	}

	.kureo-competence__description {
		opacity: 0;
		transition: opacity .35s;
		position: absolute;
		top: 50%;
		left: 0;
		translate: 0 -50%;
		pointer-events: none;

		&>*:first-child {
			margin-top: 0;
		}

		&.active {
			opacity: 1;
		}

		@media(max-width: 720px) {
			position: relative;
			display: none;
			text-align: center;

			&.active {
				display: block;
				width: 100%;
			}
		}
	}
</style>