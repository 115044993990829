export default class DarkMode {
  constructor() {
    this.darkMode = false;
    this.toggleSwitches = document.querySelectorAll('.dark-mode-toggle');
    this.checkDarkMode();
    this.addClickEvents();
  }

  addClickEvents() {
    [...this.toggleSwitches].forEach((el) => {
      el.addEventListener('change', (event) => {
        if(event.target.checked) {
          this.setDarkMode(true);
        }
        else {
          this.setDarkMode(false);
        }
      });
    });
  }

  checkDarkMode() {
    var mq = window.matchMedia("(prefers-color-scheme: dark)");
    var mql = window.matchMedia("(prefers-color-scheme: light)");
    if (mq.matches && localStorage.getItem("theme") != "light") {
      this.setDarkMode(true);
    } 
    else if (mql.matches && localStorage.getItem("theme") != "light") {
      this.setDarkMode(true);
    } 
    else {
      this.setDarkMode(false);
    }
    mq.addEventListener("change", (e) => {
      this.setDarkMode(e.matches);
    });
  }

  setTheme(theme) {
    document.body.dataset.theme = theme;
  }

  setDarkMode(newVal) {
    var theme = newVal ? "dark" : "light";
    localStorage.setItem("theme", theme);
    //this.setCookie(theme); // allows theme retrieval from server
    this.setTheme(theme);

    [...this.toggleSwitches].forEach((el) => {
      el.checked = newVal;
    });
  }

  // setCookie(theme) {
  //   const d = new Date();
  //   d.setTime(d.getTime() + (30*24*60*60*1000));
  //   let expires = "expires="+ d.toUTCString();
  //   document.cookie = "theme=" + theme + ";" + expires + ";path=/";
  // }
}