export default class Hero {
	constructor() {
		this.init();
	}

	init() {
		const reduceMotion = window.matchMedia(`(prefers-reduced-motion: reduce)`).matches;
		const herowrapper = document.querySelector('.kureo-hero-wrapper');

		if(!herowrapper) return;

		//const logoContainer = herowrapper.querySelector('.kureo-hero__container');
		const sticky = herowrapper.querySelector('.kureo-hero__sticky');
		const slogan = herowrapper.querySelector('.kureo-hero__slogan');
		const tooltip = herowrapper.querySelector('.kureo-hero__tooltip');
		const logo = herowrapper.querySelector('.kureo-logo');

		const vpHeight = document.documentElement.clientHeight; //window.innerHeight;
		const headerHeight = document.querySelector('.header').getBoundingClientRect().height;
		const vpScrollHeight = Math.round( 100 - (headerHeight / vpHeight * 100));

		logo.style.position = 'fixed';

		logo.animate(
			{
				width: ['clamp(110px, var(--wp--style--global--content-size), 1600px)','110px'],
				bottom: ['0', 'calc(100% - 90px)']
			},
			{
				timeline: new ViewTimeline({
					subject: herowrapper,
				}),
				rangeStart: 'contain 0%',
				rangeEnd: 'exit '+vpScrollHeight+'%',
				fill: 'forwards',
			}
		);

		logo.animate(
			{
				fill: ['var(--orange)']
			},
			{
				timeline: new ViewTimeline({
					subject: herowrapper,
				}),
				rangeStart: 'exit '+vpScrollHeight+'%',
				rangeEnd: 'exit 100%',
				fill: 'forwards',
			}
		);

		//prevent sticky stuff colliding in hero parallax scroll on smaller viewports
		if(vpHeight > 800 && document.documentElement.clientWidth > 900) {
			sticky.style.position = 'fixed';
			const stickyStart = vpHeight > 1000 ? 'exit-crossing 5%' : 'exit-crossing 0%';

			sticky.animate(
				{
					translate: ['0 -60vh']
				},
				{
					timeline: new ViewTimeline({
						subject: herowrapper,
					}),
					rangeStart: stickyStart,
					rangeEnd: 'exit 100%',
					easing: "ease-in",
					fill: 'forwards',
				}
			)
		}

		//stickyAnimation.finished.then(() => console.log('animated'))

		slogan.addEventListener('mousemove', (e) => {
			tooltip.style.translate = e.clientX + 'px ' + e.clientY + 'px';
		})

		slogan.addEventListener('mouseenter', () => {
			tooltip.classList.add('show');
		})

		slogan.addEventListener('mouseleave', () => {
			tooltip.classList.remove('show');
		})


		// Animate elements outside component
		const headerEl = document.querySelector('.header');
		if(headerEl) {
			headerEl.animate(
				{
					backgroundColor: ['var(--orange)', 'var(--header-bg-color)'],
					color: ['var(--white)',  'var(--header-text-color)'],
				},
				{
					timeline: new ViewTimeline({
						subject: herowrapper,
					}),
					rangeStart: 'exit '+vpScrollHeight+'%',
					rangeEnd: 'exit calc(100% + 100px)',
					fill: 'both',
				}
			);
		}
		//if(!reduceMotion) {
			document.body.animate(
				{
					backgroundColor: ['var(--orange)', 'var(--bg-color)'],
					color: ['var(--white)',  'var(--text-color)'],
				},
				{
					timeline: new ViewTimeline({
						subject: herowrapper,
					}),
					rangeStart: 'exit '+vpScrollHeight+'%',
					rangeEnd: 'exit calc(100% + 100px)',
					fill: 'both',
				}
			);
		//}

	}
}