<script setup lang="ts">
import { onMounted, ref, Ref } from 'vue';

	const reduceMotion = window.matchMedia(`(prefers-reduced-motion: reduce)`).matches;

	const curiositywrapper: Ref<HTMLElement | null> = ref(null);

	interface Area {
		id: number,
		title: string,
		description: string
	}
	const areas: Area[] = [
		{
			id: 1,
			title: 'Kompetanse',
			description: 'Kureo har over 50 års tilstedeværelse i norsk teknologi og våre ansatte har et bredt spekter av erfaring og kompetanseområder.'
		},
		{
			id: 2,
			title: 'Kuriositet',
			description: 'Med solid kunnskap og forståelse rundt egne fagfelt til grunn, er vi heller ikke redde for å begi oss ut på ukjente områder: "Det har vi aldri gjort før, det får vi sikkert til!"'
		}
	]

	const selectedArea: Ref<number> = ref(0);


	onMounted(() => {

		const vpHeight = document.documentElement.clientHeight; //window.innerHeight;
		const headerHeight = document.querySelector('.header').getBoundingClientRect().height;	
		const vpScrollHeight = Math.round( 100 - (headerHeight / vpHeight * 100));

		const animateWrapEl = curiositywrapper.value?.closest('.wp-block-group');

		const headerEl = document.querySelector('.header');
			if(headerEl) {
				headerEl.animate(
					{
						backgroundColor: ['var(--orange)', 'var(--header-bg-color)'],
						color: ['var(--white)',  'var(--header-text-color)'],
					},
					{
						timeline: new ViewTimeline({
							subject: animateWrapEl,
						}),
						rangeStart: 'exit '+vpScrollHeight+'%',
						rangeEnd: 'exit calc(100% + 100px)',
						fill: 'both',
					}
				);
			}
			if(!reduceMotion) {

				curiositywrapper.value?.animate(
					{
						translate: ['0 0', '0 12rem']
					},
					{
						timeline: new ViewTimeline({
							subject: animateWrapEl,
						}),
						rangeStart: 'exit 0%',
						rangeEnd: 'exit calc(100% + 100px)',
						fill: 'both',
						
					}
				)
				
				document.body.animate(
					{
						backgroundColor: ['var(--orange)', 'var(--bg-color)'],
						color: ['var(--white)',  'var(--text-color)'],
					},
					{
						timeline: new ViewTimeline({
							subject: animateWrapEl,
						}),
						rangeStart: 'exit '+vpScrollHeight+'%',
						rangeEnd: 'exit calc(100% + 100px)',
						fill: 'both',
						
					}
				);
			}

	})

	function selectArea(e: Event, a:Area) {
		e.preventDefault();
		selectedArea.value = a.id;
	}

	function reset() {
		setTimeout(()=> {
			if(curiositywrapper.value && !curiositywrapper.value.contains(document.activeElement)) {
				selectedArea.value = 0;
			}
		}, 500);
	}

</script>

<template>
	<div class="kureo-curiosity-wrapper" ref="curiositywrapper">
		<div class="kureo-curiosity">
			<div class="kureo-curiosity__cards">
				<template v-for="a in areas">
					<a href="#" @click="(e) => selectArea(e, a)" @blur="reset" class="kureo-curiosity__card" :class="{'active': selectedArea === a.id}">
						<div class="btn-label"><span>{{a.title}}</span></div>
					</a>
				</template>
			</div>
			<div class="kureo-curiosity__descriptions">
				<div class="kureo-curiosity__description" :class="{'active': selectedArea === 0}" ref="baseDesc">
					<p>Kureo finner du i skjæringspunktet mellom kuriositet og kompetanse.</p>
				</div>
				<template v-for="a in areas">
					<div class="kureo-curiosity__description" :class="{'active': selectedArea === a.id}">
						<h3 class="has-large-font-size">0{{ a.id }} {{ a.title }}</h3>
						<p class="has-black-color">{{ a.description }}</p>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
	@use '../../scss/variables' as *;
	// @use "sass:map";
	.kureo-curiosity-wrapper {
		//margin-bottom: var(--wp--preset--spacing--80);
		height: 50vh;

		@media(max-width: 720px) {
			height: auto;
			margin-top: 50px;
			margin-bottom: 150px;
		}
	}
	.kureo-curiosity {
		display: flex;
		justify-content: space-between;
		height: 100%;
		@media(min-width: $media-m) {

		}

		@media(min-width: 721px) {

		}

		@media(max-width: 720px) {
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	.kureo-curiosity__cards {
		width: 350px;
		flex-shrink: 0;
		color: var(--white);
		text-decoration: none;
		position: relative;

		@media(max-width: 720px) {
			height: 350px;
			margin: 0 auto;
			translate: 40px;
		}

		@media(max-width: 400px) {
			width: 300px;
			translate: 0;
		}
	}

	.kureo-curiosity__card {
		position: absolute;
		top: 50%;
		left: 70px;
		translate: -50%, 50%;
		width: 200px;
		height: 200px;
		background-color: var(--black);
		transition: scale .25s, opacity .25s, translate .25s;
		z-index: 1;
		opacity: 0.5;

		@media(max-width: 720px) {
			//left: calc(50% - 75px);
			width: 150px;
			height: 150px;
		}

		translate: 0 -50%;

		&:hover,
		&:focus,
		&.active {
			outline: none;
			scale: 1.2;
			opacity: 0.75 !important;
			z-index: 2;

			& ~ .kureo-curiosity__card {
				// opacity: 0.25;
				// translate: 0 calc(-50% + 30px);

				&+.btn-label {
					translate: -6px calc(-50% + 30px);
				}
			}

			// & + .btn-label + .kureo-curiosity__card {
			// 	opacity: 0.5 !important;
			// }

			.btn-label {
				opacity: 1;
			}
		}

		// &:has(~ .kureo-curiosity__card:hover),
		// &:has(~ .kureo-curiosity__card:focus),
		// &:has(~ .kureo-curiosity__card.active) {
		// 	translate: 0 calc(-50% - 30px);
		// 	&+.btn-label {
		// 		translate: -6px calc(-50% - 30px);
		// 	}
		// }

		// &:has(+ .btn-label + .kureo-curiosity__card:hover),
		// &:has(+ .btn-label + .kureo-curiosity__card:focus),
		// &:has(+ .btn-label + .kureo-curiosity__card.active) {
		// 	opacity: 0.5 !important;
		// }

		&.active {
			opacity: 1 !important;
			outline: none;

			@media(min-width: 721px) {
				.btn-label {
					background-color: transparent;
					backdrop-filter: unset;

					span {
						opacity: 0;
					}
				}
			}
			
		}

		.btn-label {
			position: absolute;
			top: 15px;
			left: 90%;
			//translate: -6px -50%;
			z-index: 3;
			white-space: nowrap;
			opacity: 0;
			transition: all 0.25s;
			pointer-events: none;

			@media(max-width: 720px) {
				left: 50%;
			}

			&:before,
			&:after {
				content: "";
				display: block;
				width: 8px;
				height: 8px;
				background-color: var(--orange);
				position: absolute;
				left: -30px;
				top: 50%;
				translate: 0 -50%;
			}

			&:after {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				opacity: 0.3;
				translate: -6px -50%;
			}
		}

		&:nth-of-type(1) {
			top: calc(50% - 50px);
			left: 0px;

			// .btn-label {
			// 	top: calc(50% - 150px);
			// }

			&:hover,
			&:focus,
			&.active {
				translate: -30px calc(-50% - 30px);
			}
		}

		&:nth-of-type(2) {
			left: 35px;

			.btn-label {
				top: auto;
				bottom: 15px;
				//left: calc(50% + 90px);
			}

			&:hover,
			&:focus,
			&.active {
				translate: 30px calc(-50% + 30px);
			}
		}
	}

	.kureo-curiosity__descriptions {
		display: flex;
		width: 100%;
		height: 100%;
		position: relative;
		margin-left: 20px;

		@media(max-width: 720px) {
			height: auto;
			margin-left: 0;
		}
	}

	.kureo-curiosity__description {
		opacity: 0;
		transition: opacity .35s;
		position: absolute;
		top: 50%;
		left: 0;
		translate: 0 -50%;
		pointer-events: none;

		&>*:first-child {
			margin-top: 0;
		}

		&.active {
			opacity: 1;
		}

		@media(max-width: 720px) {
			position: relative;
			display: none;
			text-align: center;

			&.active {
				display: block;
				width: 100%;
			}
		}
	}
</style>